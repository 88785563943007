import './styles.css';

const Loading = ({ size = 'base' }: { size?: 'small' | 'base' | 'large' }) => {
  const style = {
    width: 
      size === 'small' ? '34px'
      : size === 'base' ? '50px'
      : '72px',
    height:
      size === 'small' ? '34px'
      : size === 'base' ? '50px'
      : '72px',
    '--loader-border-width':
      size === 'small' ? '6px'
      : size === 'base' ? '8px'
      : '10px'
  };

  return <div className='loader' style={style} />;
};

export default Loading;
