import { ErrorText, InfoText, SuccessText, WarnText } from 'components/Text';

export type InputProps = React.FunctionComponent<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    errorText?: string;
    innerRef?: React.LegacyRef<HTMLInputElement>;
    infoText?: string;
    isLoading?: boolean;
    subLabel?: string;
    subLabelId?: string;
    successText?: string;
    warnText?: string;
  }
>;

const Input: InputProps = ({
  errorText,
  id,
  infoText,
  innerRef,
  isLoading,
  subLabel,
  successText,
  style,
  warnText,
  ...rest
}) => {
  return (
    <div className='flex flex-col gap-1'>
      {subLabel && (
        <label
          htmlFor={id}
          className='text-neutral-600 pb-1'
        >
          {subLabel}
        </label>
      )}
      <input
        id={id}
        className='
          form-control block
          w-full min-h-10
          m-0 px-2.5 py-1.5
          text-base
          text-neutral-600
          font-normal
          bg-clip-padding
          border border-solid
          rounded-[10px]
          outline-offset-2
          focus-visible:outline-2 focus-visible:outline-blue-300
          transition ease-in-out
        '
        style={{
          backgroundColor: isLoading ? '#FAFAFA' : 'white',
          borderColor: errorText ? '#F43F5F'
            : successText ? '#22C55D'
            : '#E5E5E5',
          ...style,
        }}
        disabled={isLoading}
        ref={innerRef}
        {...rest}
      />
      {
        errorText ? <ErrorText text={errorText} />
          : warnText ? <WarnText text={warnText} />
          : successText ? <SuccessText text={successText} />
          : infoText ? <InfoText text={infoText} />
          : <></>
      }
    </div>
  );
};

export default Input;
