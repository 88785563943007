const ProgressBar = ({ progress }: { progress: number }) => {
  if (progress < 0 || progress > 100) {
    return <></>;
  }

  return (
    <div className='w-full h-1.5 bg-neutral-200 rounded-[10px] overflow-hidden'>
      <div
        className='h-full bg-amber-400'
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

export default ProgressBar;
