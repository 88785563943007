import {
  useState,
  useCallback,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react';

import { User } from 'types';

interface UserContextTypes {
  user: User | null;
  clearUser: () => void;
  setUser: (userId: string, organisationId: string) => void
}

const UserContext = createContext<UserContextTypes>({
  user: null,
  clearUser(){},
  setUser(){},
});

export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [user, _setUser] = useState<User | null>(null);

  const clearUser = useCallback(() => {
    _setUser(null);
  }, []);

  const setUser = useCallback((userId: string, organisationId: string) => {
    _setUser({
      userId,
      organisationId,
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        clearUser,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
