import { virtuosisInsance } from './axios';

export const getSASToken = async (abortCtrl?: AbortController) => {
  const { data } = await virtuosisInsance.get(
    '/analytics-pipeline/v1.0/blob/sas',
    {
      signal: abortCtrl?.signal,
    },
  );

  // Checking if the data is a valid URL.
  new URL(data);

  return await data;
};
