import { Loading } from 'components/Animations';

import DateController from './DateController';

const Header = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <div
      className='
        relative
        min-h-[56px]
        p-3 flex justify-center sm:justify-between items-center
        bg-white border border-zinc-100 rounded-[10px] shadow-main
        text-sm font-medium
      '
    >
      <DateController />
      {isLoading && (
        <div className='absolute right-3'>
          <Loading size='small' />
        </div>
      )}
    </div>
  );
};

export default Header;
