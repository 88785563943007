import Card, { CardLayoutProps } from 'components/Dashboard/Charts/Card';

const MeetingDetails = ({ className }: CardLayoutProps) => {
  return (
    <Card
      className={className}
      style={{ height: '77px' }}
    >
      <div className="p-5">
        <span className="font-bold text-black">
          Meeting details
        </span>
      </div>
    </Card>
  );
};

export default MeetingDetails;
