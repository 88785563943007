import { ErrorText, InfoText, SuccessText, WarnText } from 'components/Text';

import ProgressBar from './ProgressBar';

const baseClass = `
  relative
  inline-flex
  justify-center items-center
  min-h-12
  px-6 py-2.5
  rounded
  font-medium text-center
  outline-offset-2
  transition ease-out duration-300
`;

const primaryClass = `
  ${baseClass}
  bg-[#FAC34F]
  hover:bg-[#F9AD29]
  focus-visible:outline-2 focus-visible:outline-blue-300
  active:bg-[#F3890D]
`;

const secondaryClass = `
  ${baseClass}
  bg-white
  border border-solid border-neutral-200
  hover:bg-neutral-100
  focus-visible:outline-2 focus-visible:outline-blue-300
  active:bg-neutral-200
`;

interface ComponentProps extends React.ComponentProps<'button'> {
  errorText?: string;
  isLoading?: boolean;
  infoText?: string;
  loadingWidth?: string;
  pallet?: 'primary' | 'secondary';
  progress?: number | null;
  successText?: string;
  title?: string;
  warnText?: string;
}

const Button = ({
  children,
  style,
  errorText,
  infoText,
  isLoading,
  loadingWidth,
  pallet,
  progress,
  successText,
  title,
  warnText,
  ...rest
}: ComponentProps) => {
  return (
    <div className='flex flex-col gap-1'>
      <button
        type='button'
        className={pallet === 'secondary' ? secondaryClass : primaryClass}
        style={{
          ...style,
          pointerEvents: isLoading ? 'none' : undefined,
          backgroundColor: isLoading ? '#E4E4E7' : undefined,
        }}
        disabled={isLoading}
        {...rest}
      >
        {title ?? children}
      </button>
      {
        errorText ? <ErrorText text={errorText} />
        : warnText ? <WarnText text={warnText} />
        : successText ? <SuccessText text={successText} />
        : infoText ? <InfoText text={infoText} />
        : typeof progress === 'number' ? <ProgressBar progress={progress} />
        : <></>
      }
    </div>
  );
};

export default Button;
