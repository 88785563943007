import {
  memo,
  useMemo,
  useState,
  useCallback,
} from 'react';
import dayjs from 'dayjs';
import Chart from 'react-apexcharts';

import LabelItems from 'components/Dashboard/Charts/Card/LabelItems';
import Card, {
  TrendDates,
  TrendArrow,
  TrendDateRange,
  CardLayoutProps,
} from 'components/Dashboard/Charts/Card';

import { fontFamily } from 'helpers/typography';
import {
  DATE_FORMAT,
  CURRENT_DATE,
  getYearTrends,
} from 'helpers/data';
import {
  getWeekDates,
  getYearMonths,
  getMonthDates,
} from 'helpers/dates';

import { 
  CommCoachTrend,
  CommCoachTrends,
  getDefaultTrends,
} from '../data';

type Props = CardLayoutProps & { commCoachTrends: CommCoachTrends; };

const COLORS = ['#000000', '#4E9CF2', '#FAC34F', '#AF6ED3', '#6EE3AA'];

const LABELS = ['Communication score', 'Positiveness', 'Clarity', 'Speaking rate', 'Rhythm'];

const Trends = ({ className, commCoachTrends }: Props) => {
  const [dateDelta, setDateDelta] = useState(0);

  const [activePosition, setActivePosition] = useState(TrendDateRange.Month);

  const hasData = commCoachTrends.size > 0;

  const [firstDate] = useMemo(() => Array.from(commCoachTrends)[0] || [], [commCoachTrends]);

  const isMonthChart = useMemo(() => activePosition === TrendDateRange.Month, [activePosition]);

  const isYearChart = useMemo(() => activePosition === TrendDateRange.Year, [activePosition]);

  const datesFunc = useMemo(() => {
    setDateDelta(0);

    return activePosition === TrendDateRange.Week ? getWeekDates
      : activePosition === TrendDateRange.Month ? getMonthDates
      : getYearMonths;
  }, [activePosition]);

  const dates = useMemo(() => datesFunc(dateDelta), [dateDelta, datesFunc]);

  const firstChartDate = dayjs(dates.at(0), DATE_FORMAT);

  const LastChartDate = dayjs(dates.at(-1), DATE_FORMAT);

  const isFirstDateRange = isYearChart
    ? firstDate?.startsWith(String(new Date().getFullYear() - dateDelta))
    : dates.includes(firstDate);

  const isLastDateRange = dateDelta === 0;

  const yearTrends = useMemo(() => {
    const trendKeys: (keyof CommCoachTrend)[] = [
      'rhythm',
      'overall',
      'clarity',
      'wordRate',
      'startDate',
      'positiveness',
      'fragmentRate',
      'speakingRate',
      'totalDuration',
      'speakingDuration',
    ];

    return getYearTrends(trendKeys, commCoachTrends);
  }, [commCoachTrends]);

  const datedTrends = useMemo(() => {
    const todayDate = CURRENT_DATE.format(DATE_FORMAT);

    const trends = isYearChart ? yearTrends : commCoachTrends;

    return dates.map((date, index) => {
      const trend = trends.get(date);

      if (trend?.overall !== undefined) {
        return trend;
      }

      const isMoreThanOneTrend = trends.size > 1;

      const isPastDate = date < todayDate;

      const isFirstDate = index === 0;

      const isLastDate = index === dates.length - 1;

      if (isMoreThanOneTrend && isPastDate && (isFirstDate || isLastDate)) {
        const boundryDate = Array.from(trends.keys()).reverse().find((d) => {
          return d < date && trends.get(d)?.overall !== undefined;
        }) || '';

        const boundryTrend = trends.get(boundryDate);

        if (boundryTrend?.overall !== undefined) {
          return { ...boundryTrend, startDate: date };
        }
      }

      return getDefaultTrends(date);
    });
  }, [dates, isYearChart, yearTrends, commCoachTrends]);

  const getSeriesData = useCallback((key: keyof CommCoachTrend, row: CommCoachTrend) => {
    const { startDate, [key]: value } = row || {};

    return { x: startDate ?? null, y: value ?? null };
  }, []);

  const series = useMemo(() => ([{
    name: LABELS.at(0),
    data: datedTrends.map((row) => getSeriesData('overall', row)),
  },{
    name: LABELS.at(1),
    data: datedTrends.map((row) => getSeriesData('positiveness', row)),
  },{
    name: LABELS.at(2),
    data: datedTrends.map((row) => getSeriesData('clarity', row)),
  },{
    name: LABELS.at(3),
    data: datedTrends.map((row) => getSeriesData('speakingRate', row)),
  },{
    name: LABELS.at(4),
    data: datedTrends.map((row) => getSeriesData('rhythm', row)),
  }]), [datedTrends, getSeriesData]);

  const options: ApexCharts.ApexOptions = useMemo(() => ({
    colors: COLORS,
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      fontFamily: fontFamily,
    },
    stroke: {
      width: [3, 3, 3, 3, 3],
      curve: 'monotoneCubic',
      dashArray: [0, 5, 8, 3, 0],
    },
    markers: {
      size: 3,
      hover: {
        size: 5,
      },
    },
    xaxis: {
      type: 'category',
      tickAmount: isMonthChart ? 15 : undefined,
      labels: {
        rotate: 0,
        maxHeight: 30,
        formatter: function (value) {
          const labelDate = dayjs(value);

          const format = isYearChart ? 'MMMM' : isMonthChart ? 'ddd D' : 'dddd D';

          return labelDate.isValid() ? labelDate.format(format) : '';
        },
        style: {
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      labels: {
        offsetX: -10,
        style: {
          fontWeight: 400,
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        formatter: function (_, options) {
          const { dataPointIndex } = options || {};

          const labelDate = dayjs(dates.at(dataPointIndex), DATE_FORMAT);

          const format = isYearChart ? 'MMMM YYYY' : 'D MMMM YYYY'

          return labelDate.isValid() ? labelDate.format(format) : '';
        },
      },
      y: {
        formatter: function (value, options) {
          const { seriesIndex, dataPointIndex, series: s } = options || {};
  
          if (
            s.map((r: number[]) => (r || [])[dataPointIndex ?? Infinity] || 0)
             .every((v: number) => v === 0)
          ) {
            return 'N/A';
          }

          return seriesIndex === 0 ? String(value) : `${value}%`;
        },
      },
    },
    responsive: [
      {
        breakpoint: 400,
        options: {
          xaxis: {
            tickAmount: 7,
          },
        },
      },{
        breakpoint: 768,
        options: {
          xaxis: {
            labels: {
              formatter: function (value: string) {
                const labelDate = dayjs(value);
      
                const format = isYearChart ? 'MMM' : isMonthChart ? 'D' : 'ddd';
      
                return labelDate.isValid() ? labelDate.format(format) : '';
              },
            },
          },
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 4,
            labels: {
              offsetX: -15,
            },
          },
        },
      },
    ],
  }), [dates, isMonthChart, isYearChart]);

  return (
    <Card className={className}>
      <div className="p-2 xxs:p-5">
        <div className="flex flex-wrap justify-between">
          <span className="font-medium">
            Trends
          </span>
          {firstChartDate.isValid() && LastChartDate.isValid() && (
            <span className="text-sm">
              {
                isYearChart
                  ? <>{firstChartDate.format('YYYY')}</>
                  : isMonthChart
                  ? <>{firstChartDate.format('MMMM YYYY')}</>
                  : <>{firstChartDate.format('D MMM YYYY')} - {LastChartDate.format('D MMM YYYY')}</>
              }
            </span>
          )}
        </div>
        <div className="pt-7 grid gap-2 relative">
          <TrendDates
            titles={['Year', 'Month', 'Week']}
            keyPrefix="comm-coach-trends"
            className="flex gap-4 text-sm"
            activePosition={activePosition}
            setActivePosition={setActivePosition}
          />
          <div className="lg:hidden mt-2 flex gap-4">
            <TrendArrow
              className="flex"
              dateDelta={dateDelta}
              isDisabled={!hasData || isFirstDateRange}
              setDateDelta={setDateDelta}
            />
            <TrendArrow
              className="flex"
              isRight={true}
              dateDelta={dateDelta}
              isDisabled={!hasData || isLastDateRange}
              setDateDelta={setDateDelta}
            />
          </div>
          <TrendArrow
            className="hidden lg:flex absolute top-48 -left-5"
            dateDelta={dateDelta}
            isDisabled={!hasData || isFirstDateRange}
            setDateDelta={setDateDelta}
          />
          <TrendArrow
            className="hidden lg:flex absolute top-48 -right-5"
            isRight={true}
            dateDelta={dateDelta}
            isDisabled={!hasData || isLastDateRange}
            setDateDelta={setDateDelta}
          />
          <div className="lg:pl-3" style={{ height: 315 }}>
            {hasData && (
              <Chart
                height="315px"
                series={series}
                options={options}
              />
            )}
          </div>
          <LabelItems
            labels={LABELS}
            colors={COLORS}
            keyPrefix="trends"
            className="sm:px-5 flex flex-wrap gap-5"
          />
        </div>
      </div>
    </Card>
  );
};

export default memo(Trends);
