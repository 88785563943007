import { useModal } from 'context';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { View } from 'types';

import Analyse from './Analyse';
import Charts from './Charts';
import Header from './Header';
import Menu from './Menu';
import Settings from './Settings';

const Dashboard = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInitialising, setIsInitialising] = useState(true);
  const [view, setView] = useState<View>('wellbeing');

  const { modal } = useModal();
  const { pathname } = useLocation();

  useEffect(() => {
    switch (pathname) {
      case '/wellbeing':
        setView('wellbeing');
        break;
      case '/commcoach':
        setView('commcoach');
        break;
      case '/analyse':
        setView('analyse');
        break;
      case '/settings':
        setView('settings');
        break;
      default:
        setView('wellbeing');
    }

    setIsInitialising(false);
  }, [pathname]);

  if (isInitialising) {
    return <></>;
  }

  return (
    <div className='
      min-h-screen
      grid md:grid-cols-[292px_1fr]
      z-10
    '>
      {modal}
      <div
        className={`
          fixed inset-0 md:hidden
          z-20
        bg-black bg-opacity-30 md:bg-transparent md:bg-opacity-0
          ${isMenuOpen ? '' : 'hidden'}
        `}
        onClick={() => { setIsMenuOpen(false); }}
      />
      <div className={`
        fixed top-0 left-0 bottom-0
        z-30
        grid
        duration-300 ease-out
        md:translate-x-0
        ${isMenuOpen ? '' : '-translate-x-[18.5rem]'}
      `}>
        <Menu
          setIsMenuOpen={setIsMenuOpen}
          view={view}
        />
      </div>
      <div className='
        ml-1 md:mt-5 md:my-3 md:mx-6
        grid grid-rows-[auto_1fr]
        md:col-start-2
      '>
        <Header
          setIsMenuOpen={setIsMenuOpen}
          view={view}
        />
        {
          view === 'settings' ? <Settings />
            : view === 'analyse' ? <Analyse />
            : <Charts view={view} />
        }
      </div>
    </div>
  );
};

export default Dashboard;
