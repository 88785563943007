import { Advice } from 'components/Dashboard/Charts/Card';

const advice = {
  happiness: new Advice(
    'happiness',
    { min: 0, max: 100, med: 50, high: 70 },
    {
      low: [
        'Persistent low mood? Consider talking to a therapist.',
      ],
      med: [
        'Keep a gratitude journal to focus on positive aspects of your life. To start, you can simply write down three things that are going well in your life now.',
        'Practice self-care regularly and book time in your calendar to engage in activities you enjoy.',
        'Increase social interactions. You can chat with your colleagues, go out with friends, register for a club, and say yes to activities.',
        "Go for a walk to get some sunlight and fresh air - even if it's cloudy.",
        'Live in the moment. Notice how you feel and focus on the present, not thoughts about the past or future.',
        'Reframe your thoughts with positive self-talk.',
      ],
      high: [
        "You're doing great today! Keep nurturing your happiness."
      ],
    },
  ),
  anxietyFree: new Advice(
    'anxietyFree',
    { min: 0, max: 100, med: 50, high: 90 },
    {
      low: [
        'Persistent anxiety? Consider seeking help from a therapist.',
      ],
      med: [
        'Write down your feelings and thoughts; it helps to get clarity and evacuate pressure.',
        'Try noticing the feeling of anxiety when it arises. Then, take a couple of deep breaths.',
        'Identify anxiety triggers and develop coping strategies.',
        'Show self-compassion and pardon yourself. Everyone makes mistakes.',
        'Talk to someone you trust for support. It could relieve you.',
      ],
      high: [
        "You're managing your anxiety well today. Keep it up!"
      ],
    },
  ),
  stressFree: new Advice(
    'stressFree',
    { min: 0, max: 100, med: 50, high: 90 },
    {
      low: [
        'Persistent stress? Taking a break or consulting a therapist might help.',
      ],
      med: [
        'Identify stressors and find ways to mitigate them.',
        'Prioritize physical health with sleep, exercise, and nutrition.',
        'Try relaxation techniques like meditation or yoga.',
      ],
      high: [
        "You're handling stress effectively today. Well done!"
      ],
    },
  ),
  angerFree: new Advice(
    'angerFree',
    { min: 0, max: 100, med: 50, high: 90 },
    {
      low: [
        'o	Feeling persistently angry? Taking a real break or consulting a therapist might be beneficial.',
      ],
      med: [
        'Respond thoughtfully rather than reacting impulsively; take a moment to process your emotions.',
        'Avoid taking things personally',
        'Practice empathy by considering other perspectives.',
        'Be mindful of your tone to ensure it remains calm and composed.'
      ],
      high: [
        'Great job managing your anger! Your calm demeanor likely contributes to a positive environment.'
      ],
    },
  ),
}; 

export default advice;
