import { Advice } from 'components/Dashboard/Charts/Card';

const advice = {
  positiveness: new Advice(
    'positiveness',
    { min: 0, max: 100, med: 75, high: 90 },
    {
      low: [
        'Reframe negative thoughts by focusing on positive aspects.',
        'Practice looking on the bright side of situations.',
      ],
      med: [
        'Smile while speaking; even if unseen, it conveys warmth.',
        'Start your day with gratitude journaling or positive affirmations.',
      ],
      high: [
        "Great positivity! Remember, however, to be mindful of others' feelings during difficult times to avoid seeming insensitive.",
      ],
    },
  ),
  clarity: new Advice(
    'clarity',
    { min: 0, max: 100, med: 75, high: 90 },
    {
      low: [
        'Increase your volume to ensure you are heard.',
        'Check your microphone for any issues.',
        'Articulate your words clearly and pay attention to your pronunciation.',
      ],
      med: [
        'Articulate your words clearly and pay attention to your pronunciation.',
        'Maintain good posture to reduce neck tension and improve breathing.',
        'Stay hydrated; drink a glass of water to clear your throat.',
      ],
      high: [
        'Excellent job! Your clarity likely made your message well-received. Keep up the good work.',
      ],
    },
  ),
  speakingRate: new Advice(
    'speakingRate',
    { min: 0, max: 300, med: 120, high: 150 },
    {
      low: [
        'Increase your pace slightly to keep your audience engaged.',
      ],
      med: [],
      high: [
        'Slow down and speak at a relaxed pace.',
        'Fast speaking can indicate anxiety or pressure. Take deep breaths and focus on calmness.'
      ],
    },
    {
      low: 'Your speaking rate is quite low:',
      med: 'Well done! Seems like you are in the sweet spot:',
      high: 'Your speaking rate is quite high:',
    },
  ),
  rhythm: new Advice(
    'rhythm',
    { min: 0, max: 20, med: 6, high: 8 },
    {
      low: [
        'Practice speaking in a more continuous flow to maintain engagement.',
      ],
      med: [
        'Excellent! Your speech rhythm is well-balanced and engaging.',
      ],
      high: [
        'Practice reading aloud with proper punctuation to slow down.',
        'Engage in theater exercises to enhance your control over speech rhythm and improve pacing.',
      ],
    },
    {
      low: 'The rhythm of your speech is too low:',
      med: 'Well done! Seems like you are in the sweet spot:',
      high: 'The rhythm of your speech is too high:',
    },
  ),
}; 

export default advice;
