import { useEffect } from "react";

const Settings = () => {
  useEffect(() => {
    document.title = 'Virtuosis - Settings';
  }, []);

  return (
    <></>
  );
};

export default Settings;
