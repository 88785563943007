import { virtuosisInsance } from 'helpers/axios';

import { Overall, getTrends, parseRDBData } from 'helpers/data';

export type CommCoachRow = Partial<{
  rhythm: number;
  clarity: number;
  wordRate: number;
  startDate: string;
  positiveness: number;
  fragmentRate: number;
  speakingRate: number;
  totalDuration: number;
  speakingDuration: number;
}>;

export type CommCoachTrend = CommCoachRow & Overall;

export type CommCoachTrends = Map<string, CommCoachTrend>;

export const getOverallScore = ({
  rhythm,
  clarity,
  positiveness,
  speakingRate,
}: CommCoachRow)  => {
  if (
    rhythm === undefined
    || clarity === undefined
    || positiveness === undefined
    || speakingRate === undefined
  ) {
    return undefined;
  }

  return Math.round((rhythm + clarity + positiveness + speakingRate) / 4);
};

export const getDefaultTrends = (startDate: string) => {
  return {
    startDate,
    rhythm: undefined,
    overall: undefined,
    clarity: undefined,
    wordRate: undefined,
    positiveness: undefined,
    fragmentRate: undefined,
    speakingRate: undefined,
    totalDuration: undefined,
    speakingDuration: undefined,
  };
};

export const getCommCoachRows = async (abortCtrl: AbortController) => {
  const { data, status } = await virtuosisInsance.get(
    '/insights/v2.0/communication-coach',
    {
      signal: abortCtrl?.signal,
    },
  );

  if (status !== 200) {
    throw new Error(`/communication-coach call failed with status code ${status}`);
  }

  const rows = parseRDBData(
    data,
    ['string', 'number', 'number', 'number', 'number', 'number', 'number', 'number', 'number'],
    (row: any[]): CommCoachRow => ({
      startDate: row[0],
      totalDuration: row[1],
      speakingDuration: row[2],
      rhythm: row[3],
      clarity: row[4],
      wordRate: row[5],
      positiveness: row[6],
      fragmentRate: row[7],
      speakingRate: row[8],
    }),
  );

  return {
    rows,
    trends: getTrends(rows, getOverallScore, getDefaultTrends),
  };
};
