import {
  memo,
  useMemo,
  useState,
  useCallback,
} from "react";
import dayjs from 'dayjs';
import Chart from "react-apexcharts";

import Card, {
  TrendDates,
  TrendArrow,
  TrendDateRange,
  CardLayoutProps,
} from 'components/Dashboard/Charts/Card';

import { fontFamily } from 'helpers/typography';
import {
  DATE_FORMAT,
  CURRENT_DATE,
  getYearTrends,
} from 'helpers/data';
import {
  getWeekDates,
  getYearMonths,
  getMonthDates,
} from 'helpers/dates';

import {
  WellbeingTrend,
  WellbeingTrends,
  getDefaultTrends,
} from '../data';

type Props = CardLayoutProps & { wellbeingTrends: WellbeingTrends; };

const Trends = ({ className, wellbeingTrends }: Props) => {
  const [dateDelta, setDateDelta] = useState(0);

  const [activePosition, setActivePosition] = useState(TrendDateRange.Month);

  const hasData = wellbeingTrends.size > 0;

  const [firstDate] = useMemo(() => Array.from(wellbeingTrends)[0] || [], [wellbeingTrends]);

  const datesFunc = useMemo(() => {
    setDateDelta(0);

    return activePosition === TrendDateRange.Week ? getWeekDates
      : activePosition === TrendDateRange.Month ? getMonthDates
      : getYearMonths;
  }, [activePosition]);

  const dates = useMemo(() => datesFunc(dateDelta), [dateDelta, datesFunc]);

  const isMonthChart = useMemo(() => activePosition === TrendDateRange.Month, [activePosition]);

  const isYearChart = useMemo(() => activePosition === TrendDateRange.Year, [activePosition]);

  const isFirstDateRange = isYearChart
    ? firstDate?.startsWith(String(new Date().getFullYear() - dateDelta))
    : dates.includes(firstDate);

  const firstChartDate = dayjs(dates.at(0), DATE_FORMAT);

  const LastChartDate = dayjs(dates.at(-1), DATE_FORMAT);

  const isLastDateRange = dateDelta === 0;

  const yearTrends = useMemo(() => {
    const trendKeys: (keyof WellbeingTrend)[] = [
      'overall',
      'startDate',
      'happiness',
      'angerFree',
      'stressFree',
      'anxietyFree',
      'totalDuration',
      'speakingDuration',
    ];

    return getYearTrends(trendKeys, wellbeingTrends);
  }, [wellbeingTrends]);

  const datedTrends = useMemo(() => {
    const todayDate = CURRENT_DATE.format(DATE_FORMAT);

    const trends = isYearChart ? yearTrends : wellbeingTrends;

    return dates.map((date, index) => {
      const trend = trends.get(date);

      if (trend?.overall !== undefined) {
        return trend;
      }

      const isMoreThanOneTrend = trends.size > 1;

      const isPastDate = date < todayDate;

      const isFirstDate = index === 0;

      const isLastDate = index === dates.length - 1;

      if (isMoreThanOneTrend && isPastDate && (isFirstDate || isLastDate)) {
        const boundryDate = Array.from(trends.keys()).reverse().find((d) => {
          return d < date && trends.get(d)?.overall !== undefined;
        }) || '';

        const boundryTrend = trends.get(boundryDate);

        if (boundryTrend?.overall !== undefined) {
          const { overall } = boundryTrend;

          return { ...getDefaultTrends(date), overall };
        }
      }

      return getDefaultTrends(date);
    });
  }, [dates, isYearChart, yearTrends, wellbeingTrends]);

  const getSeriesData = useCallback((key: keyof WellbeingTrend, row: WellbeingTrend) => {
    const { startDate, [key]: value } = row || {};

    return { x: startDate ?? null, y: value ?? null };
  }, []);

  const series = useMemo(() => ([{
    name: 'Anger-free',
    type: 'column',
    data: datedTrends.map((row) => getSeriesData('angerFree', row)),
  },{
    name: 'Happiness',
    type: 'column',
    data: datedTrends.map((row) => getSeriesData('happiness', row)),
  },{
    name: 'Anxiety-free',
    type: 'column',
    data: datedTrends.map((row) => getSeriesData('anxietyFree', row)),
  },{
    name: 'Stress-free',
    type: 'column',
    data: datedTrends.map((row) => getSeriesData('stressFree', row)),
  },{
    name: 'Wellness score',
    type: 'line',
    data: datedTrends.map((row) => getSeriesData('overall', row)),
  }]), [datedTrends, getSeriesData]);

  const options: ApexCharts.ApexOptions = useMemo(() => ({
    colors: ['#EF5E99', '#FAC34F', '#6EE3AA', '#4E9CF2', '#872D8D'],
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      fontFamily: fontFamily,
    },
    stroke: {
      width: [0, 0, 0, 0, 2],
      curve: 'monotoneCubic',
    },
    plotOptions: {
      bar: {
        columnWidth: '25%',
      },
    },
    markers: {
      size: 3,
      hover: {
        size: 5,
      },
    },
    xaxis: {
      type: 'category',
      tickAmount: isMonthChart ? 15 : undefined,
      labels: {
        rotate: 0,
        maxHeight: 30,
        formatter: function (value) {
          const labelDate = dayjs(value);

          const format = isYearChart ? 'MMMM' : isMonthChart ? 'ddd D' : 'dddd D';

          return labelDate.isValid() ? labelDate.format(format) : '';
        },
        style: {
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 400,
      tickAmount: 4,
      labels: {
        style: {
          fontWeight: 400,
        },
      },
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      inverseOrder: true,
      x: {
        formatter: function (_, options) {
          const { dataPointIndex } = options || {};

          const labelDate = dayjs(dates.at(dataPointIndex), DATE_FORMAT);

          const format = isYearChart ? 'MMMM YYYY' : 'D MMMM YYYY'

          return labelDate.isValid() ? labelDate.format(format) : '';
        },
      },
      y: {
        formatter: function (value, options) {
          const { seriesIndex } = options || {};

          if (value === null || value === undefined || value < 0) {
            return 'N/A';
          }

          const valueFixed = value.toFixed(0);

          return seriesIndex === 4 ? String(valueFixed) : `${valueFixed}%`;
        },
      },
    },
    responsive: [
      {
        breakpoint: 400,
        options: {
          xaxis: {
            tickAmount: 7,
          },
        },
      },{
        breakpoint: 768,
        options: {
          xaxis: {
            labels: {
              formatter: function (value: string) {
                const labelDate = dayjs(value);
      
                const format = isYearChart ? 'MMM' : isMonthChart ? 'D' : 'ddd';
      
                return labelDate.isValid() ? labelDate.format(format) : '';
              },
            },
          },
          yaxis: {
            min: 0,
            max: 400,
            tickAmount: 4,
            labels: {
              offsetX: -15,
            },
          },
          grid: {
            padding: {
              left: -5
            },
          },
        },
      },
    ],
  }), [dates, isMonthChart, isYearChart]);

  return (
    <Card className={className}>
      <div className="p-2 xxs:p-5">
        <div className="flex flex-wrap justify-between">
          <span className="font-medium">
            Trends
          </span>
          {firstChartDate.isValid() && LastChartDate.isValid() && (
            <span className="text-sm">
              {
                isYearChart
                  ? <>{firstChartDate.format('YYYY')}</>
                  : isMonthChart
                  ? <>{firstChartDate.format('MMMM YYYY')}</>
                  : <>{firstChartDate.format('D MMM YYYY')} - {LastChartDate.format('D MMM YYYY')}</>
              }
            </span>
          )}
        </div>
        <div className="pt-4 grid gap-2 relative">
          <TrendDates
            titles={['Year', 'Month', 'Week']}
            keyPrefix="wellbeing-trends"
            className="flex gap-4 text-sm"
            activePosition={activePosition}
            setActivePosition={setActivePosition}
          />
          <div className="lg:hidden mt-2 flex gap-4">
            <TrendArrow
              className="flex"
              dateDelta={dateDelta}
              isDisabled={!hasData || isFirstDateRange}
              setDateDelta={setDateDelta}
            />
            <TrendArrow
              className="flex"
              isRight={true}
              dateDelta={dateDelta}
              isDisabled={!hasData || isLastDateRange}
              setDateDelta={setDateDelta}
            />
          </div>
          <TrendArrow
            className="hidden lg:flex absolute top-48 -left-5"
            dateDelta={dateDelta}
            isDisabled={!hasData || isFirstDateRange}
            setDateDelta={setDateDelta}
          />
          <TrendArrow
            className="hidden lg:flex absolute top-48 -right-5"
            isRight={true}
            dateDelta={dateDelta}
            isDisabled={!hasData || isLastDateRange}
            setDateDelta={setDateDelta}
          />
          <div className="lg:pl-3 lg:pr-5" style={{ height: 315 }}>
            {hasData && (
              <Chart
                type="bar"
                height="315px"
                series={series}
                options={options}
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default memo(Trends);
