import { useCallback } from 'react';
import { useModal } from 'context';

import HorizontalBar from './HorizontalBar';
import RangeBar, { Range } from './RangeBar';

import { Tip } from './utils';

import arrowDown from 'assets/images/arrow-down-neutral.svg';

import './style.css';

interface AdviceItemProps {
  rate?: number;
  unit?: string;
  title: string;
  score: number | undefined;
  range?: Range;
  header?: string;
  advice: Tip[];
  position: number;
  definition?: JSX.Element;
  activePosition: number | null;
  backgroundColor: string;
  setActivePosition: React.Dispatch<React.SetStateAction<number | null>>;
}

const AdviceItem = ({
  rate,
  unit,
  title,
  score,
  range,
  header,
  advice,
  position,
  definition,
  activePosition,
  backgroundColor,
  setActivePosition,
}: AdviceItemProps) => {
  const { openModal, ModalWrapper } = useModal();

  const isNoScore = score === undefined;

  const isActive = position === activePosition && !isNoScore;

  const handleClick = useCallback(() => {
    setActivePosition(isActive ? null : position);
  }, [isActive, position, setActivePosition]);

  return (
    <div
      className="grid items-center text-sm"
      style={{
        gridTemplateRows: 'auto 1fr',
        gridTemplateColumns: 'auto 1fr'
      }}
    >
      <button
        onClick={handleClick}
        disabled={isNoScore}
        className='pr-2'
      >
        <img
          className={`${isActive ? 'arrow-down__open' : 'arrow-down__closed'}`}
          width={20}
          height={20}
          src={arrowDown}
          alt={`${title} open/close controller`}
        />
      </button>
      <div className="max-w-[580px] flex flex-wrap justify-between">
        <button
          className="text-left font-medium"
          style={{ minWidth: '90px' }}
          onClick={handleClick}
          disabled={isNoScore}
        >
          {title}
        </button>
        <HorizontalBar
          score={score}
          backgroundColor={backgroundColor}
        />
      </div>
      <div
        className={`
          col-start-2 flex flex-col advice-item
          ${isActive ? 'mt-4 active-item__open' : 'h-0 opacity-0'}
        `}
      >
        {range && (
          <>
            <span>
              {header}
            </span>
            <RangeBar
              rate={rate}
              unit={unit}
              range={range}
              backgroundColor={backgroundColor}
            />
          </>
        )}
        {
          advice?.length > 1
            ? (
              <ul className="xs:pl-3.5 list-disc grid gap-0.5 text-neutral-600">
                {advice.map(({ key, text }) => (
                  <li key={key}>
                    {text}
                  </li>
                ))}
              </ul>
            )
            : advice?.length === 1
              ? (
                <span className="text-neutral-600">
                  {advice[0].text}
                </span>
              )
            : <></>
        }
        {definition && (
          <div className="mt-2 flex">
            <button
              className="hover:underline underline-offset-2 font-medium"
              style={{ color: '#565FC4' }}
              onClick={() => {
                openModal(
                  <ModalWrapper
                    title={title}
                    childrenClassName="w-112 max-w-full min-h-64"
                  >
                    <div className="flex flex-col gap-3">
                      {definition}
                    </div>
                  </ModalWrapper>
                )
              }}
            >
              Learn more
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdviceItem;
