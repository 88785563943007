import { useRef, useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { useDate } from 'context';

import Trends from './Trends';
import DailyOverview from './DailyOverview';
import PersonalizedAdvice from './PersonalizedAdvice';

import { WellbeingRow, WellbeingTrends, getWellbeingRows } from './data';

type Props = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
};

const Wellbeing = ({ setIsLoading }: Props) => {
  const { date } = useDate();

  const abortCtrl = useRef(new AbortController());

  const [selectedRow, setSelectedRow] = useState<WellbeingRow>({});

  const [wellbeingRows, setWellbeingRows] = useState<WellbeingRow[]>([]);

  const [wellbeingTrends, setWellbeingTrends] = useState<WellbeingTrends>(new Map());

  useEffect(() => {
    (async () => {
      try {
        abortCtrl.current = new AbortController();

        const { rows, trends } = await getWellbeingRows(abortCtrl.current);

        setWellbeingRows(rows);

        setWellbeingTrends(trends);

        setIsLoading(false);
      } catch (err) {
        Sentry.captureException(err);

        // TODO: Create an error experience.
      }
    })()

    return () => { abortCtrl.current.abort(); };
  }, [setIsLoading]);

  useEffect(() => {
    if (wellbeingRows?.length) {
      const dateFormatted = date.format('YYYY-MM-DD');

      setSelectedRow(
        wellbeingRows.find(({ startDate }) => {
          return startDate === dateFormatted;
        }) || {}
      );
    }
  }, [date, wellbeingRows]);

  useEffect(() => {
    document.title = 'Virtuosis - Mental health check up';
  }, []);

  return (
    <div className='grid grid-cols-1 xl:grid-cols-2 gap-4'>
      <DailyOverview
        className='min-h-88'
        wellbeingRow={selectedRow}
      />
      <PersonalizedAdvice
        className='min-h-88'
        wellbeingRow={selectedRow}
      />
      <Trends
        className='min-h-96 col-start-1 col-end-2 xl:col-end-3'
        wellbeingTrends={wellbeingTrends}
      />
    </div>
  );
};

export default Wellbeing;
