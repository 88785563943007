import { useMemo } from "react";
import Chart from "react-apexcharts";

import { fontFamily } from 'helpers/typography';
import Card, { CardLayoutProps } from 'components/Dashboard/Charts/Card'

import LabelItem from './LabelItem';

import { WellbeingRow, getOverallScore } from '../data';

type Props = CardLayoutProps & { wellbeingRow: WellbeingRow; };

const DailyOverview = ({ className, wellbeingRow }: Props) => {
  const series = useMemo(() => ([
    wellbeingRow?.stressFree || 0,
    wellbeingRow?.anxietyFree || 0,
    wellbeingRow?.happiness || 0,
    wellbeingRow?.angerFree || 0,
  ]), [wellbeingRow]);

  const options: ApexCharts.ApexOptions = {
    colors: ['#4E9CF2', '#6EE3AA', '#FAC34F', '#EF5E99', '#872D8D'],
    labels: ['Stress-free', 'Anxiety-free', 'Happiness', 'Anger-free'],
    plotOptions: {
      radialBar: {
        offsetY: -20,
        dataLabels: {
          name: {
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: fontFamily,
            offsetY: 15,
          },
          value: {
            fontSize: '16px',
            fontWeight: 600,
            fontFamily: fontFamily,
            offsetY: -20,
            formatter: function (value: number) {
              return value ? `${value}%` : '';
            },
          },
          total: {
            show: true,
            fontSize: '14px',
            label: 'Wellness score',
            fontWeight: 400,
            fontFamily: fontFamily,
            color: '#525252',
            formatter: function (options: any) {
              const currentSeries = options?.config?.series || [];

              const wellnessScore = getOverallScore({
                happiness: currentSeries[2],
                angerFree: currentSeries[3],
                stressFree: currentSeries[0],
                anxietyFree: currentSeries[1],
              });

              return !wellnessScore || wellnessScore < 1
                ? ''
                : `${wellnessScore}%`;
            },
          },
        },
      },
    },
  };

  return (
    <Card className={className}>
      <div className="p-2 xxs:p-5 grid">
        <span className="font-medium">
          Daily overview
        </span>
        <div className="max-w-112 w-full pt-4 m-auto flex justify-center sm:justify-between gap-4 sm:gap-0 flex-wrap sm:flex-nowrap items-center">
          <div style={{maxWidth: '250px', maxHeight: '250px' }}>
            <Chart
              type="radialBar"
              height="315px"
              series={series}
              options={options}
            />
          </div>
          <div className="flex justify-center">
            <ul 
              className="grid gap-1.5 sm:gap-3"
              style={{ width: '129px' }}
            >
              <LabelItem
                label="Stress-free"
                value={wellbeingRow?.stressFree}
                backgroundColor="#4E9CF2"
              />
              <LabelItem
                label="Anxiety-free"
                value={wellbeingRow?.anxietyFree}
                backgroundColor="#6EE3AA"
              />
              <LabelItem
                label="Happiness"
                value={wellbeingRow?.happiness}
                backgroundColor="#FAC34F"
              />
              <LabelItem
                label="Anger-free"
                value={wellbeingRow?.angerFree}
                backgroundColor="#EF5E99"
              />
            </ul>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DailyOverview;
