import { useEffect, useState } from 'react';

import { View } from 'types';

import CommCoach from './CommCoach';
import Header from './Header';
import Wellbeing from './Wellbeing';

const Charts = ({ view }: { view: View }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
  }, [view]);

  return (
    <div className='grid grid-rows-[auto_1fr] gap-4'>
      <Header isLoading={isLoading} />
      {
        view === 'wellbeing'
          ? <Wellbeing setIsLoading={setIsLoading} />
          : view === 'commcoach'
            ? <CommCoach setIsLoading={setIsLoading} />
            : <></>
      }
    </div>
  )
};

export default Charts;
