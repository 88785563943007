export interface Range {
  min: number;
  max: number;
  med: number;
  high: number;
};

interface BarGraphProps {
  rate: number | undefined;
  unit: string | undefined;
  range: Range | undefined;
  backgroundColor: string;
}

const BAR_WIDTH = 200;

const HorizontalBar = ({
  rate,
  unit,
  range,
  backgroundColor,
}: BarGraphProps) => {
  const { med, high, max } = range || {}

  if (
    rate === undefined
    || med === undefined
    || high === undefined
    || max === undefined
    || high <= med
    || max <= high
  ) {
    return <></>;
  }

  const rangeStart = Math.floor((med / max) * 100);

  const rangeEnd = Math.floor((high / max) * 100);

  const startWidth = rangeStart * 2;

  const endWidth = (rangeEnd * 2) - startWidth;

  const inactiveWidth = BAR_WIDTH - startWidth - endWidth;

  const scorePosition = Math.floor(((rate / max) * 100) * 2);

  return (
    <div className="h-5 flex items-center gap-2.5">
      <div
        className="flex items-center"
        style={{ maxWidth: '200px' }}
      >
        <div
          className="absolute"
          style={{
            left: `${scorePosition}px`,
            width: '2px',
            height: '16px',
            backgroundColor: '#EB2A7A',
          }}
        />
        <div
          className="h-2.5 grid"
          style={{ gridTemplateColumns: `${startWidth}px ${endWidth}px ${inactiveWidth}px` }}
        >
          <div style={{ backgroundColor, opacity: 0.2 }} />
          <div style={{ backgroundColor }} />
          <div style={{ backgroundColor, opacity: 0.2 }} />
        </div>
      </div>
      {unit && (
        <span className="text-neutral-600">{rate} {unit}</span>
      )}
    </div>
  );
};

export default HorizontalBar;
