import { CSSProperties } from 'react';

import './style.css';

export interface CardLayoutProps {
  style?: CSSProperties;
  className?: string;
}

interface CardProps extends CardLayoutProps {
  children?: React.ReactNode
}

const Card: React.FC<CardProps> = ({
  style,
  children,
  className,
}) => {
  return (
    <div
      className={`
        ${className ?? ''}
        bg-white border border-zinc-100 rounded-[10px] shadow-main
      `}
      style={style}
    >
      {children}
    </div>
  );
};

export default Card;
