import { useEffect, useCallback, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useUser } from 'context';
import { v4 as uuidv4 } from 'uuid';
import { newUTCString } from 'helpers';
import { type Meeting } from 'types';
import { BlobServiceClient } from '@azure/storage-blob';

import Input from 'components/Input';
import Button from 'components/Button';

import { getSASToken } from 'helpers/api';

const DEFAULT_DURATION_ERROR = 'Please enter a number greater than 0.';

const Analyse = () => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const [duration, setDuration] = useState<number | null>(null);
  
  const [success, setSuccess] = useState('');
  const [durationError, setDurationError] = useState('');
  const [inputError, setInputError] = useState('');
  const [buttonError, setButtonError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);

  const [RecId, setRecId] = useState(uuidv4());
  const [MeetingId, setMeetingId] = useState(uuidv4());

  const { user } = useUser();

  const getBlobClient = async () => {
    try {
      const sasToken = await getSASToken();

      if (!sasToken) {
        throw new Error('Missing SAS token');
      }

      const newClient = new BlobServiceClient(sasToken);

      const containers = newClient.listContainers();
  
      for await (const _ of containers) {} // eslint-disable-line

      return newClient;
    } catch (err) {
      Sentry.captureException(err);
      setButtonError('An error occurred - please refresh and try again');
      return null;
    }
  };

  const uploadBlob = useCallback(async () => {
    try {
      setDurationError('');
      setInputError('');
      setButtonError('');
      setSuccess('');

      const isFile = file instanceof File;
      const isDurationValid = duration && duration > 0;

      if (!user) {
        setButtonError('An error occurred - please refresh and try again');
        return;
      }

      if (!isFile) {
        setInputError('Please select an audio file.');
      }

      if (!isDurationValid) {
        setDurationError(DEFAULT_DURATION_ERROR);
      }

      if (!isFile || !isDurationValid) {
        return;
      }

      setIsLoading(true);
      setProgress(0);

      const client = await getBlobClient();

      if (!client) {
        setButtonError('An error occurred - please refresh and try again');
        setIsLoading(false);
        setProgress(null);
        return;
      }

      const containerClient = client.getContainerClient('recordings');

      const ext = file.name.split('.').slice(-1);

      const blockBlobClient = containerClient.getBlockBlobClient(`${RecId}.${ext}`);

      const currentDate = new Date();

      const metadata: Meeting = {
        UserId: user.userId,
        OrganisationId: user.organisationId,
        ChatId: MeetingId,
        Provider: 'Google',
        StoreRaw: 'false',
        MeetingId: uuidv4(),
        EndDateTime: newUTCString(currentDate),
        StartDateTime: newUTCString(currentDate, duration),
      };

      await blockBlobClient.upload(file, file.size, {
        metadata: { ...metadata },
        onProgress: (p) => {
          const { loadedBytes } = p || {};
          if (typeof loadedBytes !== 'number' || loadedBytes < 0) {
            return;
          }
          const progress = Math.round((loadedBytes / file.size) * 100);
          setProgress(progress);
        },
      });

      if (fileRef.current) {
        fileRef.current.value = '';
      }

      setFile(null);
      setRecId(uuidv4());
      setMeetingId(uuidv4());
      setDuration(null);
      setProgress(null);
      setSuccess('Audio successfully uploaded!');
    } catch (err) {
      Sentry.captureException(err);
      setProgress(null);
      setButtonError('Upload failed - refresh page and try again.');
    }

    setIsLoading(false);
  }, [user, file, duration, RecId, MeetingId]);

  useEffect(() => {
    document.title = 'Virtuosis - Analyse audio';
  }, []);

  return (
    <div className='
      flex justify-center
      px-5 py-24 md:px-10 md:py-20 xl:px-20
    '>
      <div className='
        w-full min-w-60 max-w-112
        flex flex-col
        gap-y-8
      '>
        <div className='w-full'>
          <Input
            id='meeting-duration'
            subLabel='Meeting duration (seconds)'
            isLoading={isLoading}
            errorText={durationError}
            value={duration !== null ? String(duration) : ''}
            onChange={(e) => {
              setDurationError('');
              setButtonError('');

              const { target } = e || {};
              const { value } = target || {};

              if (value === "") {
                setDurationError(DEFAULT_DURATION_ERROR);
                setDuration(null);
                return;
              }

              const _duration = parseInt(value, 10);

              if (Number.isNaN(_duration)) {
                setDurationError(DEFAULT_DURATION_ERROR);
                setDuration(null);
                return;
              }

              setDuration(_duration);

              if (_duration < 1) {
                setDurationError(DEFAULT_DURATION_ERROR);
              }
            }}
          />
        </div>
        <div className='w-full grid gap-2'>
          <Input
            type='file'
            id='formFile'
            innerRef={fileRef}
            isLoading={isLoading}
            onChange={(e) => {
              setInputError('');
              setButtonError('');

              const files = e.target.files || new FileList();
              const file = files[0];

              if (!file || !fileRef.current) {
                setFile(null);
                return;
              }

              if (file.type !== 'audio/x-m4a' && file.type !== 'audio/wav' && file.type !== 'audio/mpeg') {
                setInputError('Unsupported file type selected.');
                fileRef.current.value = '';
                setFile(null);
                return;
              }

              if ((file.size || 0) > 1073741824) {
                setInputError('File size must be less than 1GB.');
                fileRef.current.value = '';
                setFile(null);
                return;
              }

              setFile(file);
            }}
            infoText='Supported file types: .m4a, .wav, .mp3 (max 1GB)'
            errorText={inputError}
          />
        </div>
        <div className='w-full'>
          <Button
            title='Analyse'
            onClick={uploadBlob}
            isLoading={isLoading}
            progress={progress}
            errorText={buttonError}
            successText={success}
          />
        </div>
      </div>
    </div>
  );
};

export default Analyse;
