import { useMemo } from "react";
import Chart from "react-apexcharts";

import { fontFamily } from 'helpers/typography';
import Card, { CardLayoutProps } from 'components/Dashboard/Charts/Card';

import { CommCoachTrend } from "../data";

type Props = CardLayoutProps & { commCoachTrend: CommCoachTrend; };

const COLORS = ['#4E9CF2', '#FAC34F', '#AF6ED3', '#6EE3AA'];

const LABELS = ['Positiveness', 'Clarity', 'Speaking rate', 'Rhythm'];

const DailyOverview = ({ className, commCoachTrend }: Props) => {
  const series = useMemo(() => ([
    commCoachTrend?.positiveness || 0,
    commCoachTrend?.clarity || 0,
    commCoachTrend?.speakingRate || 0,
    commCoachTrend?.rhythm || 0,
  ]), [commCoachTrend]);

  const options: ApexCharts.ApexOptions = {
    colors: COLORS,
    labels: LABELS,
    chart: {
      type: 'polarArea',
      height: 280,
    },
    stroke: {
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: true,
      formatter: function (_, options) {
        const { w, seriesIndex } = options || {};

        const { series: s } = w?.globals || {};

        if (s.every((v: number) => v === 0)) {
          return '';
        }

        return `${(s || [])[seriesIndex ?? Infinity] || 0}%`;
      },
      background: {
        enabled: true,
        foreColor: '#262626',
        borderColor: '#E5E5E5',
        dropShadow: {
          enabled: false,
        },
      },
      style: {
        fontSize: '12px',
        fontFamily: fontFamily,
        fontWeight: 500,
        colors: ['#FFF'],
      },
    },
    legend: {
      show: false,
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        formatter: (_) => '',
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <Card className={className}>
      <div className="p-2 xxs:p-5">
        <span className="font-medium">
          Daily overview
        </span>
        <div className="pt-5 flex flex-col justify-center items-center m-auto gap-3">
          <Chart
            type="polarArea"
            height={280}
            series={series}
            options={options}
          />
          <p className="w-[190px] flex flex-col items-center">
            <span className="text-neutral-600">
              Communication score:
            </span>
            <span className="h-12 text-[#D76408] text-[32px] font-medium">
              {commCoachTrend?.overall !== undefined && <>{commCoachTrend.overall}%</>}
            </span>
          </p>
          {/* <LabelItems
            labels={LABELS}
            colors={COLORS}
            keyPrefix="daily-overview"
            className="px-5 flex flex-wrap justify-center gap-3"
          /> */}
        </div>
      </div>
    </Card>
  );
};

export default DailyOverview;
