import axios from 'axios';
import { useUser } from 'context';
import { virtuosisInsance } from 'helpers/axios';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';

import Init from 'components/Init';
import { SubscriptionError } from 'components/Subscription';

const SubscriptionProtected = ({ children }: { children: JSX.Element }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean | null>(null);

  const navigate = useNavigate();
  const { clearUser, setUser } = useUser();

  useEffect(() => {
    const abortController = new AbortController();

    (async (signal: AbortSignal) => {
      try {
        setHasError(false);
        const endpoint = '/subscription/v1.0/status/google';
        const response = await virtuosisInsance.get(endpoint, { signal });
        const data = response?.data;
        const { hasLicence, userId, organisationId } = data || {};
        if (typeof hasLicence !== 'boolean') {
          throw new Error('Invalid response data: ' + JSON.stringify(data));
        }
        if (hasLicence) {
          if (!userId || !organisationId) {
            throw new Error('Missing user or organisation ID: ' + JSON.stringify(data));
          }
          setUser(userId, organisationId);
        } else {
          clearUser();
        }
        setIsSubscribed(hasLicence);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }
        setHasError(true);
        Sentry.captureException(err);
      }
    })(abortController.signal);

    return () => { abortController.abort(); };
  }, [clearUser, navigate, setUser]);

  if (hasError) {
    return <SubscriptionError />;
  }

  if (isSubscribed === null) {
    return <Init isLoading={true} />;
  }

  return isSubscribed ? children : <Navigate to='/trial' />;
};

export default SubscriptionProtected;
