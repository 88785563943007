import { useEffect, useState } from 'react';

import Card, { AdviceItem, CardLayoutProps } from 'components/Dashboard/Charts/Card';

import { CommCoachTrend } from "../data";

import advice from './advice';
import definations from './definations';

type Props = CardLayoutProps & { commCoachTrend: CommCoachTrend; };

const PersonalizedAdvice = ({ className, commCoachTrend }: Props) => {
  const [activePosition, setActivePosition] = useState<number | null>(null);

  useEffect(() => { setActivePosition(null); }, [commCoachTrend]);

  return (
    <Card className={className}>
      <div
        className="p-2 xxs:p-5 flex flex-col"
        style={{ minHeight: '338px' }}
      >
        <span className="font-medium">
          Personalized advice
        </span>
        <div className="pt-4 flex flex-col gap-4">
          <AdviceItem
            title="Positiveness"
            position={0}
            activePosition={activePosition}
            setActivePosition={setActivePosition}
            backgroundColor="#249EFA"
            score={commCoachTrend?.positiveness}
            advice={advice.positiveness.advice(commCoachTrend?.positiveness)}
            definition={definations.positiveness}
          />
          <AdviceItem
            title="Clarity"
            position={1}
            activePosition={activePosition}
            setActivePosition={setActivePosition}
            backgroundColor="#FDBA3A"
            score={commCoachTrend?.clarity}
            advice={advice.clarity.advice(commCoachTrend?.clarity)}
            definition={definations.clarity}
          />
          <AdviceItem
            title="Speaking rate"
            position={2}
            activePosition={activePosition}
            setActivePosition={setActivePosition}
            backgroundColor="#BB6BD9"
            rate={commCoachTrend?.wordRate}
            unit="words per minute"
            score={commCoachTrend?.speakingRate}
            range={advice.speakingRate.range}
            header={advice.speakingRate.header(commCoachTrend?.wordRate)}
            advice={advice.speakingRate.advice(commCoachTrend?.wordRate)}
            definition={definations.speakingRate}
          />
          <AdviceItem
            title="Rhythm"
            position={3}
            activePosition={activePosition}
            setActivePosition={setActivePosition}
            backgroundColor="#24E6A4"
            rate={commCoachTrend?.fragmentRate}
            unit="pauses per minute"
            score={commCoachTrend?.rhythm}
            range={advice.rhythm.range}
            header={advice.rhythm.header(commCoachTrend?.fragmentRate)}
            advice={advice.rhythm.advice(commCoachTrend?.fragmentRate)}
            definition={definations.rhythm}
          />
        </div>
      </div>
    </Card>
  );
};

export default PersonalizedAdvice;
